import React, { useState, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { restartProcessingVivenuPayment, getSeatsInformation, getPayment } from '../api-client'
import Error from '../components/Error'
import Warning from '../components/Warning'
import Spinner from '../components/Spinner'
import logo from '../images/logo.webp'

enum Status {
  INIT = 'INIT',
  MISSING_PAYMENT_ID = 'PAYMENT_ID_MISSING',  
  FETCHING_SEAT_INFORMATION = 'FETCHING_SEAT_INFORMATION',
  FETCHING_SEAT_INFORMATION_FAILED = 'FETCHING_SEAT_INFORMATION_FAILED',
  NEEDS_CONFIRMATION = 'NEEDS_CONFIRMATION',
  FETCHING_REDIRECT_URL = 'FETCHING_REDIRECT_URL',
  FETCHING_REDIRECT_URL_FAILED = 'FETCHING_REDIRECT_URL_FAILED',
  PAYMENT_ALREADY_PROCESSED = 'PAYMENT_ALREADY_PROCESSED',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  INVALID_STATUS = 'INVALID_STATUS',
  PAYMENT_CANCELED = 'PAYMENT_CANCELED',
  PAYMENT_SUCCEEDED = 'PAYMENT_SUCCEEDED',
  REQUEST_FAILED = 'REQUEST_FAILED',
  PROCESSING_FAILED = 'PROCESSING_FAILED'
}

enum ErrorStatus {
  MISSING_PAYMENT_ID = 'missing_payment_id',
  PAYMENT_CANCELED = 'payment_canceled',
  REQUEST_FAILED = 'request_failed',
  PROCESSING_FAILED = 'processing_failed',
  INVALID_STATUS = 'invalid_status'
}

const timeouts = [1, 2, 3, 5, 8]

const VerifyPaymentPage: React.FC = () => {
  const timeoutIDRef = useRef<NodeJS.Timeout | null>(null)

  const [searchParams] = useSearchParams()
  const [paymentId, setPaymentId] = useState<string | null>(null)
  const [status, setStatus] = useState<Status>(Status.INIT)
  const [fetchPaymentRetries, setFetchPaymentRetries] = useState<number>(0)
  const [failureReturnUrl, setFailureReturnUrl] = useState<string | null>(null)

  const setTimeoutID = (timeoutID: NodeJS.Timeout | null) => {
    timeoutIDRef.current = timeoutID
  }

  const getRedirectUrl = async () => {
    try {
      const payment = await getPayment(paymentId!)
      if (payment == null) {
        return setFetchPaymentRetries(fetchPaymentRetries + 1)
      } else {
        const { successReturnUrl, vivenuPaymentStatus, molliePaymentStatus,mollieCheckoutUrl } = payment
        if (vivenuPaymentStatus === 'SUCCEEDED' && molliePaymentStatus === 'paid') {
          // @ts-ignore
          window.top.location.href = successReturnUrl
        }
        if (mollieCheckoutUrl == null) {
          return setFetchPaymentRetries(fetchPaymentRetries + 1)
        } else {
          // @ts-ignore
          window.top.location.href = mollieCheckoutUrl
        }
      }
    } catch (error) {
      setFetchPaymentRetries(fetchPaymentRetries + 1)
    }
  }

  const getSeatingInformation = async () => {
    try {
      const { noSeating, adjacent, failureReturnUrl } = await getSeatsInformation(paymentId!)
      if (noSeating || (!noSeating && adjacent)) {
        setStatus(Status.FETCHING_REDIRECT_URL)
        return getRedirectUrl()
      }
      if (!adjacent) {
        // Ask for user permission to continue
        setFailureReturnUrl(failureReturnUrl)
        return setStatus(Status.NEEDS_CONFIRMATION)
      }
    } catch (error) {
      return setStatus(Status.FETCHING_SEAT_INFORMATION_FAILED)
    }
  }

  const onCancel = () => {
    // @ts-ignore
    window.top.location.href = failureReturnUrl
  }

  const onConfirm = () => {
    getRedirectUrl()
  }

  const onRetry = () => {
    switch (status) {
      case Status.REQUEST_FAILED:
        setStatus(Status.INIT)
        return restartProcessingVivenuPayment(searchParams.get('paymentId')!)
      case Status.PROCESSING_FAILED:
        setStatus(Status.INIT)
        return restartProcessingVivenuPayment(searchParams.get('paymentId')!)
      case Status.FETCHING_SEAT_INFORMATION_FAILED:
        setStatus(Status.FETCHING_SEAT_INFORMATION)
        return getSeatingInformation()
      case Status.FETCHING_REDIRECT_URL_FAILED:
        setStatus(Status.FETCHING_REDIRECT_URL)
        return getRedirectUrl()
    }
  }

  const getErrorMessage = () => {
    switch (status) {
      case Status.MISSING_PAYMENT_ID:
        return 'A payment id is required to be able to verify the payment request.'
      case Status.PAYMENT_CANCELED:
        return `Something went wrong... The payment with id ${searchParams.get('paymentId')} couldn't be verified.`
      case Status.FETCHING_REDIRECT_URL_FAILED:
        return `Something went wrong... The payment with id ${searchParams.get('paymentId')} couldn't be verified. Please try again.`
      case Status.INVALID_STATUS:
        return `Something went wrong... The payment with id ${searchParams.get('paymentId')} has an invalid status.`
      case Status.REQUEST_FAILED:
        return `Something went wrong... The payment with id ${searchParams.get('paymentId')} couldn't be verified. Please try again.`
      case Status.PROCESSING_FAILED:
        return `Something went wrong... The payment with id ${searchParams.get('paymentId')} couldn't be verified. Please try again.`
      default:
        return `Something went wrong... The payment with id ${searchParams.get('paymentId')} couldn't be verified. Please try again.`
    }
  }

  const getWarningMessage = () => [
    `Jullie zitplaatsen bevinden zich niet naast elkaar omdat de categorie die jullie hebben gekozen bijna is uitverkocht. 
    Als jullie willen, kunnen jullie een andere categorie proberen of gewoon doorgaan zoals gepland.`,
    `Your seats are not next to each other because the category you chose is almost sold out. 
    If you want, you can try another category or just go ahead as planned.`
  ]

  useEffect(() => {
    if (fetchPaymentRetries > 0 && fetchPaymentRetries < 5) {
      const timeoutID = setTimeout(() => {
        getRedirectUrl()
      }, timeouts[fetchPaymentRetries] * 1000)
      setTimeoutID(timeoutID)
    } else if (fetchPaymentRetries !== 0) {
      setFetchPaymentRetries(0)
      setStatus(Status.FETCHING_REDIRECT_URL_FAILED)
    }
  }, [fetchPaymentRetries])


  // Step 2: The payment id is stored in the state, request seating information.
  // The result of this will indicate whether or not all seats are neighbours.
  useEffect(() => {
    if (paymentId != null) {
      setStatus(Status.FETCHING_SEAT_INFORMATION)
      getSeatingInformation()
    }
  }, [paymentId])

  // Step 1: Read the payment id from the url and store it in the state.
  useEffect(() => {
    const pId = searchParams.get('paymentId')
    const error = searchParams.get('error')
    const status = searchParams.get('status')
    
    if (error != null) {
      // Handle error if any.
      if (error === ErrorStatus.PAYMENT_CANCELED) {
        // Payment was canceled, we can't do anything show appropriate message
        return setStatus(Status.PAYMENT_CANCELED)
      } else if (error === ErrorStatus.MISSING_PAYMENT_ID) {
        // Payment id is missing, we can't do anything show appropriate message
        return setStatus(Status.MISSING_PAYMENT_ID)
      } else if (error === ErrorStatus.REQUEST_FAILED) {
        // Request failed, retry
        return setStatus(Status.REQUEST_FAILED)
      } else if (error === ErrorStatus.PROCESSING_FAILED) {
        // Processing failed, retry
        return setStatus(Status.PROCESSING_FAILED)
      } else if (error === ErrorStatus.INVALID_STATUS) {
        // Invalid status, we can't do anything show appropriate message
        return setStatus(Status.INVALID_STATUS)
      }
    }

    if (status != null && status === 'payment_succeeded') {
      // Payment was successful and already processed, show appropriate message.
      return setStatus(Status.PAYMENT_SUCCEEDED)
    }

    // No error and no status, we can continue.
    if (pId !== paymentId) {
      setPaymentId(pId)
    }
  }, [])

  const showSpinner = status === Status.INIT || status === Status.FETCHING_SEAT_INFORMATION || status === Status.FETCHING_REDIRECT_URL
  const showWarning = status === Status.NEEDS_CONFIRMATION
  const showError = status === Status.FETCHING_SEAT_INFORMATION_FAILED || status === Status.FETCHING_REDIRECT_URL_FAILED || status === Status.MISSING_PAYMENT_ID || status === Status.INVALID_STATUS || status === Status.PAYMENT_CANCELED
  const showRetry = status === Status.FETCHING_SEAT_INFORMATION_FAILED || status === Status.FETCHING_REDIRECT_URL_FAILED || status === Status.REQUEST_FAILED || status === Status.PROCESSING_FAILED

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-screen flex flex-col justify-center">
      <div className=" px-4 sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src={logo}
          alt=""
        />
      </div>
      <div className="max-w-lg mx-auto text-center">
        <h1 className="text-3xl font-medium text-gray-900">Verifying your payment request</h1>
        <p className="mt-2.5 text-xl text-red-600">Please do not refresh this page.</p>
        {!showWarning && !showError && <p className="mt-2.5 text-xl text-gray-500">Once we've verified your payment request you'll be redirected to Mollie.</p>}
        {showWarning && <Warning className="mt-2.5 text-left" messages={getWarningMessage()} />}
        {showError && <Error className="mt-2.5 text-left" message={getErrorMessage()} />}
        {showSpinner && (<Spinner className="mt-2.5" />)}
        {showRetry && (
          <button
            type="button"
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
            onClick={onRetry}
          >
            Try again
          </button>
        )}
        {showWarning && (
          <>
            <button
              type="button"
              className="mt-4 mr-8 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              onClick={onConfirm}
            >
              Ga verder / Proceed
            </button>
            <button
              type="button"
              className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              onClick={onCancel}
            >
              Ga terug / Go back
            </button>
          </>
        )}
      </div> 
    </div>
  )
}

export default VerifyPaymentPage