const getPayment = async (paymentId: string) => {
  const response = await fetch(`${process.env.REACT_APP_API_GATEWAY}/payment?paymentId=${paymentId}`)

  if (!response.ok) {
    const message = `An error has occured: ${response.status}`
    throw new Error(message)
  }

  const payment = await response.json()

  return payment
}

const restartProcessingVivenuPayment = async (paymentId: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_GATEWAY}/vivenu-payment-redirect?paymentId=${paymentId}`,
      {
        redirect: 'follow' // explicitly follow redirects
      }
    )

    // Check if response was redirected
    if (response.redirected) {
      // Perform client-side redirect to the new URL
      window.location.href = response.url
      return // The page will reload, no need to continue
    }

    window.location.href = `${process.env.REACT_APP_PAYMENT_GATEWAY_URL}/verify-payment?paymentId=${paymentId}&error=request_failed`
  } catch (error) {
    // Handle network errors or other exceptions
    window.location.href = `${process.env.REACT_APP_PAYMENT_GATEWAY_URL}/verify-payment?paymentId=${paymentId}&error=request_failed`
    return
  }
}

const restartProcessingMolliePayment = async (paymentId: string) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_GATEWAY}/mollie-payment-redirect?paymentId=${paymentId}`,
      {
        redirect: 'follow' // explicitly follow redirects
      }
    )

    if (response.redirected) {
      window.location.href = response.url
      return
    }

    window.location.href = `${process.env.REACT_APP_PAYMENT_GATEWAY_URL}/confirm-payment?paymentId=${paymentId}&error=request_failed`
  } catch (error) {
    window.location.href = `${process.env.REACT_APP_PAYMENT_GATEWAY_URL}/confirm-payment?paymentId=${paymentId}&error=request_failed`
    return
  }
}

const getSeatsInformation = async (paymentId: string) => {
  const response = await fetch(`${process.env.REACT_APP_API_GATEWAY}/seats-check?paymentId=${paymentId}`)
  if (!response.ok) {
    const body = await response.json()
    throw new Error(body.error)
  }

  const data = await response.json()

  return data
}

export { restartProcessingVivenuPayment, getPayment, getSeatsInformation, restartProcessingMolliePayment }