import React, { useState, useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { getPayment, restartProcessingMolliePayment } from '../api-client'
import Error from '../components/Error'
import Info from '../components/Info'
import Spinner from '../components/Spinner'
import logo from '../images/logo.webp'

enum Status {
  INIT = 'INIT',
  MISSING_PAYMENT_ID = 'MISSING_PAYMENT_ID',
  REQUEST_FAILED = 'REQUEST_FAILED',
  FETCHING_PAYMENT = 'FETCHING_PAYMENT',
  FETCHING_PAYMENT_FAILED = 'FETCHING_PAYMENT_FAILED',
  CHECKOUT_IS_PAID_BUT_NOT_CONFIRMED_AT_VIVENU = 'CHECKOUT_IS_PAID_BUT_NOT_CONFIRMED_AT_VIVENU'
}

enum ErrorStatus {
  MISSING_PAYMENT_ID = 'missing_payment_id',
  REQUEST_FAILED = 'request_failed'
}

const timeouts = [1, 2, 3, 5, 8]

const ConfirmPaymentPage: React.FC = () => {
  const timeoutIDRef = useRef<NodeJS.Timeout | null>(null)

  const [searchParams] = useSearchParams()
  const [paymentId, setPaymentId] = useState<string | null>(null)
  const [count, setCount] = useState<number>(0)
  const [payment, setPayment] = useState<any>(null)
  const [status, setStatus] = useState<Status>(Status.INIT)

  const setTimeoutID = (timeoutID: NodeJS.Timeout | null) => {
    timeoutIDRef.current = timeoutID
  }

  const onRetry = () => {
    switch (status) {
      case Status.REQUEST_FAILED:
        setStatus(Status.INIT)
        return restartProcessingMolliePayment(searchParams.get('paymentId')!)
      case Status.FETCHING_PAYMENT_FAILED:
        setStatus(Status.FETCHING_PAYMENT)
        return fetchPayment()
    }
  }

  const fetchPayment = async () => {
    try {
      const payment = await getPayment(paymentId!)
      console.log('Payment: ', payment)
      setPayment(payment)
      const { vivenuPaymentStatus, molliePaymentStatus } = payment

      if ((vivenuPaymentStatus === 'NEW' && molliePaymentStatus === 'open') || (vivenuPaymentStatus === 'NEW' && molliePaymentStatus === 'paid')) {
        // Statuses still need to be confirmed, retry
        setCount(count + 1)
      } else if (vivenuPaymentStatus === 'CANCELED' && molliePaymentStatus === 'open') {
        // We were not able to confirm the payment at vivenu before the checkout expired.
        window.location = payment.failureReturnUrl
      } else if (molliePaymentStatus === 'paid' && vivenuPaymentStatus === 'SUCCEEDED') {
        // Redirect to vivenu success
        window.location = payment.successReturnUrl
      } else if (molliePaymentStatus === 'expired' || molliePaymentStatus === 'failed' || molliePaymentStatus === 'canceled') {
        // Redirect to vivenu failure
        window.location = payment.failureReturnUrl
      } else if (vivenuPaymentStatus === 'CANCELED' && molliePaymentStatus === 'paid') {
        // We were not able to confirm the payment at vivenu before the checkout expired.
        setStatus(Status.CHECKOUT_IS_PAID_BUT_NOT_CONFIRMED_AT_VIVENU)
      }
    } catch (error) {
      setStatus(Status.FETCHING_PAYMENT_FAILED)
      setCount(0)
    }
  }

  const getErrorMessage = () => {
    switch (status) {
      case Status.MISSING_PAYMENT_ID:
        return 'A payment id is required to be able to verify the payment request.'
      case Status.REQUEST_FAILED:
        return `Something went wrong... The payment with id ${searchParams.get('paymentId')} couldn't be verified. Please try again.`
      case Status.FETCHING_PAYMENT_FAILED:
        return `Something went wrong... The payment with id ${paymentId} couldn't be verified. Please try again.`
      default:
        return `Something went wrong... The payment with id ${paymentId} couldn't be verified. Please try again.`
    }
  }

  useEffect(() => {
    if (count === 5) {
      if (payment.molliePaymentStatus === 'open' && payment.vivenuPaymentStatus === 'NEW') {
        window.location = payment.failureReturnUrl
      } else if (payment.molliePaymentStatus === 'paid' && payment.vivenuPaymentStatus === 'NEW') {
        // We haven't successfully confirmed the payment at vivenu by now, we allow the customer to retry.
        setCount(0)
        setStatus(Status.REQUEST_FAILED)
      }
    } else if (count !== 0) { 
      const timeoutID = setTimeout(() => {
        fetchPayment()
      }, timeouts[count] * 1000)
      setTimeoutID(timeoutID)
    }
  }, [count])

  useEffect(() => {
    if (paymentId != null) {
      setStatus(Status.FETCHING_PAYMENT)
      fetchPayment()
    }
  }, [paymentId])

  useEffect(() => {
    const pId = searchParams.get('paymentId')
    const error = searchParams.get('error')

    if (error != null) {
      // Handle error if any.
      if (error === ErrorStatus.MISSING_PAYMENT_ID) {
        // Payment id is missing, we can't do anything show appropriate message
        return setStatus(Status.MISSING_PAYMENT_ID)
      } else if (error === ErrorStatus.REQUEST_FAILED) {
        // Request failed means we were not able to fetch the payment from mollie and or update the mollie payment status in the database.
        // In this case we alsways need to allow the customer to retry to get to a final state.
        return setStatus(Status.REQUEST_FAILED)
      }
    }

    if (pId !== paymentId) {
      return setPaymentId(pId)
    }
  }, [])

  const showSpinner = status === Status.INIT || status === Status.FETCHING_PAYMENT
  const showError = status === Status.REQUEST_FAILED || status === Status.MISSING_PAYMENT_ID || status === Status.FETCHING_PAYMENT_FAILED
  const showAdvancedError = status === Status.CHECKOUT_IS_PAID_BUT_NOT_CONFIRMED_AT_VIVENU
  const showRetry = status === Status.REQUEST_FAILED || status === Status.FETCHING_PAYMENT_FAILED

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-screen flex flex-col justify-center">
      <div className="px-4 sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-12 w-auto"
          src={logo}
          alt=""
        />
      </div>
      <div className="max-w-lg mx-auto text-center">
        <h1 className="text-3xl font-medium text-gray-900">Confirming your payment request</h1>
        <p className="mt-2.5 text-xl text-red-600">Please do not refresh this page.</p>
        {!showError && !showAdvancedError && <p className="mt-2.5 text-xl text-gray-500">Once we've confirmed your payment you'll be redirected to the webshop.</p>}
        {showAdvancedError && (
          <Error className="mt-2.5 text-left" message={`We were not able to confirm your payment at ticket.monster.
            Please contact info@ticket.monster for a refund.
            Include the following details:`}>
            <ul role="list" className="list-disc pl-5 space-y-1">
              <li>{`Mollie payment id: ${payment!.mollieId}`}</li>
              <li>{`Checkout id: ${payment!.checkoutId}`}</li>
            </ul>
          </Error>
        )}
        {showError && !showRetry && <Error className="mt-2.5 text-left" message="A payment id is required to be able to verify the payment request." />}
        {showSpinner && (<Spinner className="mt-2.5" />)}
        {showRetry && (
          <>
            <Info className="mt-2.5 text-left" message="We were not able to confirm your payment." additionalMessage="Please try again or contact support@ticket.monster if the issues persist." />
            <button
              type="button"
              className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              onClick={onRetry}
            >
              Try again
            </button>
          </>
        )}
      </div> 
    </div>
  )
}

export default ConfirmPaymentPage